<template>
<!-- 关注商品 -->
  <div>
      <div class="title">我的收藏</div>
      <ul class="guess_goods">
        <li v-for="(item,index) in goodsList" :key="index" style="cursor: pointer" @click="goGoodsDetail(item)">
          <img :src="item.uccCommodityRspBO.uccCommodityPicRspBOList[0].commodityPicUrl" alt="">
          <p class="by2">{{item.uccCommodityRspBO.commodityName}}</p>
          <div><span class="price">￥{{item.uccCommodityRspBO.minPrice/10000}}</span>
          <!-- <p><span class="sheng">广西</span><span class="shi">桂林</span></p> -->
          </div>
        </li>
    </ul>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'common',
  data(){
      return{
          goodsList: []
      }
  },
  mounted(){
      this.search()
  },
  methods:{
    search(){
      this.qa.queryCommodityFavoritesPageList({}).then(res => {
        this.goodsList = res.data.rows;
        
        this.total = res.data.recordsTotal;
        console.log(this.goodsList)
      })
    },
    goGoodsDetail(row){
      this.$router.push({
        name: 'goodsDetail',
        query: {
          id: row.uccCommodityRspBO.id
        }
      })
    },
  }
}
</script>
<style scoped  lang="scss">
    .guess_goods {
    /* // width: 228px; */
    text-align: left;
    width: 1020px;
    margin: 0 auto;
    li {
        margin-right: 10px;
        width: 196px;
        display: inline-block;
        margin-top: 13px;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 0px 8px 0px rgba(128, 128, 128, 0.5);
        >img {
            width: 196px;
            height: 196px;
            border-radius: 8px;
        }
        >p {
            padding: 6px 4px 18px;
            text-align: left;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
            height: 50px;
            margin-bottom: 18px;
        }
        >div {
            padding: 0 4px 10px;
            text-align: left;
            .price {
                display: inline-block;
                /* // float: left; */
                color: rgba(55, 132, 0, 1);
                font-size: 20px;
                vertical-align: middle;
            }
            >p{
                display: inline-block;
                vertical-align: middle;
                /* // display: inline-block;
                // float: right; */
                float: right;
                line-height: 20px;
                text-align: right;
            }
        }
    }
    li:nth-child(5n) {
        margin-right: 0;
    }
}
.title {
    font-size: 18px;
    font-weight: 600;
}
</style>